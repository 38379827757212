import React from "react";
import { useDispatch, useSelector, useEf } from "react-redux";
import "./Header.css";
import { logout } from "../../../redux/actions/authActions";
import logo from "../../../assets/images/HIT_logo.png";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom'
import { getHomeData } from "../../../redux/actions/dataActions";
import { useEffect } from "react";

const maxMobileWidth = 767;

const Header = (props) => {
  const navigate = useNavigate()

  const dispatch = useDispatch();

  return (
    <section className="header is-sticky">
      <img src={logo} alt="logo" className="logo" onClick={() => navigate('/')} />
    </section>
  );
};

export default Header;
