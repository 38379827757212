import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Button, Select, MenuItem } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Table_calc from "./Table_calc";

function Step2(props) {

  const { required, setRequired, optionals, setOptionals, handleInputChange, getTotalSum, displayError} = props;

  const predefinedElements = [
    { id: 'physics', name: 'פיזיקה' },
    { id: 'chemistry', name: 'כימיה' },
    { id: 'bio', name: 'ביולוגיה' },
    { id: 'computer', name: 'מדעי המחשב' },
    { id: 'medicine', name: 'רפואה' },
    { id: 'biotech', name: 'ביו-טכנולוגיה' },
    { id: 'info', name: 'מידע ונתונים' },
    { id: 'other', name: 'אחר' },
  ];

  

  const addOptional = () => {
    setOptionals([
      ...optionals,
      { id: '', name: '', units: null, grade: null, total: null, width_bonus: null, unit_error: false,  can_exclude: true }
    ]);
  };

  const deleteOpt = (index) => {
    setOptionals(optionals.filter((_, i) => i !== index));
  };

  const handleSelectChange = (index, value) => {
    const updatedElements = optionals.map((element, i) => {
      if (i == index) {
        const selectedElement = predefinedElements.find(e => e.id == value);
        return {
          ...element,
          id: value,
          name: selectedElement ? selectedElement.name : '',
        };
      }
      return element;
    });

    setOptionals(updatedElements);
  };

 return (
    <div className="step step2">
       
      <Table_calc key='required_el' elements={required} handleInputChange={handleInputChange} isOptional={false} displayError={displayError} ></Table_calc>
      <Table_calc  key='opt_el'  elements={optionals} handleInputChange={handleInputChange} handleSelectChange={handleSelectChange} isOptional={true} predefinedElements={predefinedElements} displayError={displayError} deleteOpt={deleteOpt}></Table_calc>
      <Button variant="contained" color="primary" onClick={addOptional} endIcon={<AddRoundedIcon/>} >הוסף מקצוע בחירה </Button>
      <div className="total">ציון משוקלל: <span className="bold">{getTotalSum()}</span></div>
    </div>
  ); 

}

export default Step2;
