import { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Button, Select, MenuItem, IconButton, useMediaQuery } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();

const Table_calc = (props) => {
    const {elements, handleSelectChange, handleInputChange, predefinedElements, isOptional, setError, displayError, deleteOpt } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const title = 'מקצועות ' + (isOptional ? 'בחירה' : 'חובה');
    return( elements.length ? 
        <TableContainer component={Paper} className={isOptional ? 'opt_table' : 'req_table'}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> {title} </TableCell>
              <TableCell>יחידות</TableCell>
              <TableCell>ציון</TableCell>
              <TableCell>עם בונוס</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {elements.map((element, i) => (
              <TableRow key={i}>
                 <TableCell>
                  {!isOptional ? (
                    element.name
                  ) : (
                    <div className="optionalEl"><IconButton color="primary" onClick={() => deleteOpt(i)} aria-label="delete"  sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: isMobile ? 19 : 24, 
                      },
                    }}><RemoveCircleOutlineRoundedIcon/></IconButton>
                    <Select
                      defaultValue={element.id ?? ''}
                      value={element.id ?? ''}
                      onChange={(e) => handleSelectChange(i, e.target.value)}
                      displayEmpty
                      fullWidth
                       size="small"
                    >
                      <MenuItem value="" disabled>בחר מיקצוע...</MenuItem>
                      {predefinedElements.map(preElement => (
                        <MenuItem key={preElement.id} value={preElement.id}>{preElement.name}</MenuItem>
                      ))}
                    </Select>
                    </div>

                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    key={element.id+'_unit'}
                    error={element.unit_error || (displayError && !element.units) }
                    disabled={!element.name}
                    type="tel"
                    variant="outlined"
                    size="small"
                    inputProps={{max: 5}}
                    defaultValue={element.units}
                    onChange={e => {
                      handleInputChange(element.id, 'units', e.target.value, isOptional) }}
                      required={isOptional? false : true}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                  key={element.id+'_grade'}
                   disabled={!element.name}
                   error={displayError && !element.grade}
                    type="tel"
                    variant="outlined"
                    size="small"
                    defaultValue={element.grade}
                    onChange={e => handleInputChange(element.id, 'grade', e.target.value, isOptional)}
                    required={isOptional? false : true}

                  />
                </TableCell>
                <TableCell>
                {element.excluded ? 'יושמט' : element.width_bonus}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> : <></>
    )
}

export default Table_calc;