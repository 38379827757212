import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Steps.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, Button } from '@mui/material';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(subject, five_u, four_u,) {
  return { subject, five_u, four_u };
}

function Step1(props) {

  const { next } = props;

  const rows = [
    createData('מתמטיקה', 35, 15),
    createData('אנגלית, כימיה, ביולוגיה, מדעי המחשב, פיזיקה, אזרחות, תנ"ך, היסטוריה, ספרות, מידע ונתונים', 25, 12.5),
    createData('שאר המקצועות	', 10, 20),
  ];

  return (
    <div className="step step1">
      <h3>חישוב הממוצע המשוקלל של ציוני הבגרות:</h3>

      <p>ממוצע זה מתקבל לאחר השמטת הציונים במקצועות בחירה, הגורמים להורדת ממוצע תעודת הבגרות של המועמד.<br />
        זאת בתנאי שסך כל יחידות הלימוד, שבהן מחושב הממוצע הוא 20 לפחות.<br />
        הממוצע כולל בונוסים*, הניתנים לציוני הבחינות, ברמה של ארבע יחידות לימוד ויותר.<br />

        המקצועות אנגלית, מתמטיקה, ביולוגיה, כימיה, מדעי המחשב, פיזיקה, רפואה, וביו-טכנולוגיה מזכים את המועמד בבונוס מיוחד*:
      </p>
      <br />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>מקצוע</TableCell>
              <TableCell>בונוס* עבור<br />5 יח' לימוד</TableCell>
              <TableCell>בונוס* עבור<br />4 יח' לימוד</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.subject}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.subject}</TableCell>
                <TableCell>{row.five_u}</TableCell>
                <TableCell>{row.four_u}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p className="comment">
        * בונוס יינתן רק בעבור מקצועות בציון 60 ומעלה
      </p>

      <p>
        <b>שימו לב!</b><br />
        המחשבון המוצג באתר משמש ככלי עזר בלבד! <br />
        בכל סתירה בין תוצאת כלי עזר זה לבין המופיע במערכות המכון, מערכות המכון הן הקובעות.
      </p>



      <Button variant="contained"
        className="start bold"
        onClick={next}
        endIcon={<ChevronLeftRoundedIcon />}
      >כניסה למחשבון</Button>
    </div>
  );
}

export default Step1;
