import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Header from '../layout/header/Header'
import './Content.css'
import Footer from '../layout/footer/Footer'
import { getHomeData } from '../../redux/actions/dataActions'
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Form from "../form/Form"
import Snackbar from '@mui/material/Snackbar';
import { close } from '../../redux/actions/modalActions'



function Content() {
    const { modal_name } = useSelector((state) => state.modal);

  const dispatch = useDispatch()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(close())
  }


  return (
    <div className='content_page' dir="rtl">
      <div className='content'>
        <Header /> 
       <Routes>
            <Route key={"form"} path={"/"} element={<Form />} />
             </Routes> 
                          <Snackbar
                open={modal_name == 'snack_bar'}
                autoHideDuration={2000}
                onClose={handleClose}
                message="הפעולה בוצעה בהצלחה"
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              />

      </div>
    </div>
  )
}

export default Content
