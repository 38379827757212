import { useEffect, useState } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Button, Select, MenuItem } from '@mui/material';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
function Step4(props) {

  const { questions, optionals, required, getTotalSum } = props;
  const [selectedSubject, setselectedSubject] = useState(null)


  const totalSum = getTotalSum();

  const subjects = [
    { id: 'industrialDesign', name: 'עיצוב תעשיית' },
    { id: 'communicationDesign', name: 'עיצוב תקשורת חזותית' },
    { id: 'interiorDesign', name: 'עיצוב פנים' },
    { id: 'digitalTechMedicine', name: 'טכנולוגיות דיגיטליות ברפואה' },
    { id: 'appliedMath', name: 'מתמטיקה שימושית' },
    { id: 'info', name: 'מדעי המחשב' },
    { id: 'electro', name: 'הנדסת חשמל ואלקטרוניקה' },
    { id: 'indusEngineer', name: 'הנדסת תעשייה וניהול' },
    { id: 'learnTech', name: 'טכנולוגיות למידה' },
  ];

  var isMore30 = questions.find(item => item.id === 'age')?.value;
  var isPracticalEngineer = questions.find(item => item.id === 'certificat')?.value;
  var engineerRes = questions.find(item => item.id === 'certificat_results')?.value;
  var psyco = questions.find(item => item.id === 'psyco')?.value;
  var psycoRes = questions.find(item => item.id === 'psyco_results')?.value;
  var previousStudies = questions.find(item => item.id === 'previousStudies')?.value;
  var previousStudiesRes = questions.find(item => item.id === 'previousStudies_results')?.value;


  var bachelor = totalSum > 56;
  var acceptCond = {
    industrialDesign: bachelor,
    communicationDesign: bachelor,
    interiorDesign: bachelor,
    digitalTechMedicine: (totalSum > 90  && ((psyco && psycoRes >= 600) || totalSum > 104 || (previousStudies && previousStudiesRes > 84) || (isPracticalEngineer && engineerRes > 84))),
    appliedMath: (bachelor && ((psyco && psycoRes >= 550) || (isPracticalEngineer && engineerRes > 79) || (totalSum >= 102))),
    info: (bachelor && ((psyco && psycoRes >= 550) || (isPracticalEngineer && engineerRes > 79) || (totalSum >= 102))),
    electro: (bachelor && ((psyco && psycoRes >= 550) || (isPracticalEngineer && engineerRes > 79) || (totalSum >= 102))),
    indusEngineer: (bachelor && ((psyco && psycoRes >= 550) || (isPracticalEngineer && engineerRes > 79) || (totalSum >= 102))),
    learnTech:  (bachelor && ((psyco && psycoRes >= 550) || (isPracticalEngineer && engineerRes > 79) || (totalSum >= 102) || isMore30))
  }

  var handleSelectChange = (val) => {
    setselectedSubject(val)
  }

  return (
    <div className="step step4">
      <div className='selectSubject'>
        <h3>
          בחירת חוג לימודים
        </h3>
        <Select
          defaultValue={''}
          onChange={(e) => handleSelectChange(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="" disabled>בחירת חוג לימודים</MenuItem>
          {subjects.map(elmt => (
            <MenuItem key={elmt.id} value={elmt.id}>{elmt.name}</MenuItem>
          ))}
        </Select>
      </div>

      <div className='results'>
        {selectedSubject && (acceptCond[selectedSubject] ?
          <>
            <span className="accept"> <TaskAltRoundedIcon />מעולה! יש לך את תנאי הקבלה לתוכנית.</span> <br /> <span>נשלח לשוחח בטל'<a href="tel:*6404"> 6404* </a></span>
          </> :
          ((totalSum >= 90) || ((selectedSubject == 'digitalTechMedicine' && (isPracticalEngineer && engineerRes > 84)) || (selectedSubject !== 'digitalTechMedicine' && (isPracticalEngineer && engineerRes > 79))  ))?
          <><span>תנאי הקבלה כמעט מתאימים לדרישות הסף. </span><br/> <span> נשלח לשוחח בטל' <a href="tel:*6404"> 6404* </a></span></>:
          <span> נא ליצור קשר בטל'  <a href="tel:*6404"> 6404* </a>.</span>
           )
        }
      </div>

      <div className="footer">HIT מקדם העדפה מתקנת בתנאי הקבלה לאוכלוסיות ייחודיות.
        <br /> לפרטים נוספים  יש ליצור קשר בטל'
        <a href="tel:*6404"> 6404* </a>
      </div>

    </div>
  );
}

export default Step4;
