import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, Button  } from '@mui/material';


function Step3(props) {

  const { questions, setQuestions} = props;
 
  const handleChange = (value, id) => {
    console.log(id + ':' + value);
    const updatedQuestions = questions.map(q => {
      if (q.id == id) {
        console.log(id)
        return {
          ...q,
          value: value,
        };
      }
      return q;
    });
    setQuestions(updatedQuestions)
  }

  const shouldDisplayPsycoResults = questions?.find(q => q.id === 'psyco')?.value;
  const shouldDisplayStudiesResults = questions?.find(q => q.id === 'previousStudies')?.value;
  const shouldDisplayCertificatResults = questions?.find(q => q.id === 'certificat')?.value;


  return (
    <div className="step step3">

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {questions?.map((q) => (
              ((q.id !== 'psyco_results' || shouldDisplayPsycoResults) && (q.id !== 'previousStudies_results' || shouldDisplayStudiesResults)  && (q.id !== 'certificat_results' || shouldDisplayCertificatResults)) && (
                <TableRow
                  key={q.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">  
                  <FormControlLabel
                    key={q.id}
                    
                    control={ (q.type == 'toggle') ? 
                      <Switch
                        checked={q.value}
                        onChange={(e) => handleChange(e.target.checked, q.id)}
                        color='primary' />  :
                    
                      <TextField
                      type="tel"
                      variant="outlined"
                      defaultValue={q.value}
                      size='small'
                      onChange={e => {
                        handleChange( e.target.value, q.id) }}
                    />
                    
                    }

                    label={q.label}
                    labelPlacement="start"

                  /> 
                  </TableCell>
                </TableRow>)
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Step3;
