import {Provider} from 'react-redux' // redux
import store from './redux/store/store' // Redux
import { BrowserRouter as Router } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {heIL} from '@mui/material/locale';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Content from './components/content/Content';
import { grey } from '@mui/material/colors';
import './App.css'
import { BorderAllRounded } from '@mui/icons-material';
function App() {
  
const theme = createTheme({
  direction: 'rtl',
  palette: {
    primary: {
      main:'#007a82',
    },
    secondary: {
      main: grey[500],
    },
  },
  typography: {
    fontFamily: [
      'NarkissBlock',
      'NarkissBlock-Light',
      'NarkissBlock-Bold'
    ].join(','),
    
  },
  
  overrides: {
    MuiButton: {
      root: {
      }, 
    }, 
  }, 
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#00555b',
          color: '#fff'
        },
      },
    },
    MuiTableCell:{
      styleOverrides: {
        head: {
          color: '#fff',
        },
        root:{
          fontSize: 16,
        }
      },
    }
    ,
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: '#f5f5f5', // Background color for odd rows
          },
        },
      },
    },
    MuiFormControlLabel:{
     styleOverrides: {
        root: {
          justifyContent:'start'
        },
      }, 
    }
      
  },
 
},
heIL,
);
  
  
// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
},);
  return (
    <Router>
      <Provider store={store}>
         <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme}>
              <div dir="rtl" className='phone_container'>
                <Content />
              </div>
            </ThemeProvider>
          </CacheProvider>
      </Provider>
    </Router>
  )
}

export default App
